import React, { MouseEvent } from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  PageTitle,
  TitleBorder
} from '@bscs-dev-team/bscs-design-system-core'

import Donate from '../components/donate/donate'
import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import rowImage from '../queries/images/row-image'


const handleScroll = (e: MouseEvent) => {
  e.preventDefault()

  document?.getElementById('annual-fund')?.scrollIntoView({behavior: "smooth", block: "start"})
}

const DonatePage = ({ data }) => {
  return (
    <React.Fragment>
      <SEO
        title="Donate To Science R&D Programs - Teacher Instructional Materials"
        description="Donate to BSCS Science Learning an independent nonprofit offering science  instructional materials & professional development training across the US."
        canonicalUrl="https://bscs.org/donate"
      />
      <Layout>
        <div>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link className='inline-anchor' to='/'>Home</Link>
            </BreadcrumbItem>
            <BreadcrumbItem>
              Donate
            </BreadcrumbItem>
          </Breadcrumb>
          <PageTitle>Donate</PageTitle>
          <TitleBorder />
          <div className="flex flex-wrap-reverse mt-5">
            <div className="md:w-1/2 lg:w-3/5 w-full">
              <Img
                className="card-img-top mb-3 rounded"
                fluid={data.astronautGirl.childImageSharp.fluid}
                alt="Young girl in an astronaut suit pointing at the moon."
                style={{
                  width: '100%'
                }}
              />
              <p>
                A donation to BSCS Science Learning makes a difference! We appreciate any contribution, whether it is inspired by what BSCS has meant to you personally, or by what you know BSCS has the ability to accomplish for teachers and students around the country. With your support, BSCS will remain a leader in science education for the next 60 years.
              </p>
              <p>Choose from one of four funds to support:</p>

              <ul>
                <li><strong>Annual Fund:</strong> supports current priorities and the mission of BSCS</li>
                <li><strong>Endowment Fund:</strong> provides BSCS with a stable source of income to sustain key programs over the long-term</li>
                <li><strong>Equity &amp; Social Justice Initiative:</strong> helps BSCS pursue a more equitable, inclusive, and just future for science teaching and learning</li>
                <li><strong>Susan Loucks-Horsley Memorial Fund:</strong> supports staff development for BSCS employees as a tribute to the memory of Susan Loucks-Horsley</li>
              </ul>

              <p>Learn more about each fund&nbsp;
                <a onClick={handleScroll} className='inline-anchor'>
                  below.
                </a>
              </p>

              {/* <p>If you would like to make a donation, please mail a check or money order to BSCS at the following address:</p> */}

              <p>To make a contribution, please complete the donation form here. If you'd prefer to support BSCS with a check, you can send it to the following address:</p>

              <p className="mb-0 ml-5">BSCS Science Learning</p>
              <p className="mt-0 mb-0 ml-5">5415 Mark Dabling Blvd.</p>
              <p className="mt-0 ml-5">Colorado Springs, CO 80918</p>

              <p>You may also call BSCS at 719-531-5550 to pay with a credit card over the phone.</p>

              <p>Other ways to support BSCS:</p>
              <ul>
                <li>
                  <strong>AmazonSmile:</strong> <a href="https://smile.amazon.com/" target="_blank" rel="noopener noreferrer">AmazonSmile&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"></i></sup></a> donates .5% of your purchase price to BSCS (at no additional cost to you) on qualifying Amazon purchases. Sign-up is simple (we're listed as BSCS Science Learning).
                </li>
                <li>
                  <strong>iGive:</strong> <a href="https://www.igive.com/welcome/lp16/cr64a.cfm" target="_blank" rel="noopener noreferrer">iGive&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"></i></sup></a> donates a percentage of each purchase to BSCS (at no additional cost to you) when you shop at more than 1,300 participating online stores. Register now (we're listed as BSCS Science Learning).
                </li>

                <li>
                  <strong>Colorado Gives Day:</strong> <a href="https://www.coloradogives.org/BSCS/overview" target="_blank" rel="noopener noreferrer">Colorado Gives Day&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"></i></sup></a> occurs every December as a statewide movement that celebrates and increases philanthropy in Colorado through online giving. The $1.5 Million Incentive Fund made possible by Community First Foundation, FirstBank, and other community members boosts the value of every donation. Learn more about Colorado Gives Day and how you can support BSCS Science Learning's work <a href="https://www.coloradogives.org/BSCS/overview" target="_blank" rel="noopener noreferrer">here&nbsp;<sup><i className="fas fa-external-link-alt external-link-icon"></i></sup></a>.
                </li>
              </ul>
              <p id="annual-fund"><strong>Annual Fund</strong></p>
              <p>This fund helps to support the many robust research and development programs we are currently advancing in service of our mission to transform science and teaching and learning. We are leading large instructional materials development projects, building capacity for next generation science instruction, and conducting nationally-recognized science education research all at once. At BSCS, we secure grants to support our research-driven innovation. But because grants restrict the type of work we can do, we rely on our unrestricted annual fund to help us expand programs and make them broadly accessible. Your contribution here ensures our impact continues beyond the life of funded projects.</p>

              <p><strong>Endowment Fund</strong></p>
              <p>A donation to the BSCS endowment is an investment in the future of science education and the strong leadership essential for students to succeed in the fields of science and technology. The BSCS Endowment Fund was established in February 2000 and is intended to fund organizational initiatives in the future. Anticipating the future need for discretionary funds, our predecessors established an endowment fund that could be used to support new initiatives. This endowment pays out annually, now that it has reached $1 million. Achieving this milestone in 2018&mdash;our 60th anniversary&mdash;gives us a reliable stream of capital to invest in crucial, new programs.</p>

              <p><strong>Equity &amp; Social Justice Fund</strong></p>
              <p>A donation to this initiative supports BSCS's efforts to pursue a more equitable, inclusive, and just future for science teaching and learning. We are striving to break historical and institutional barriers to science for marginalized students and teachers. Our vision is that one day, all educators and learners will have access to a high quality science education, feel confident applying science effectively in their lives, and be prepared to understand and redress social and environmental injustices. We are exploring opportunities to pursue this initiative across our instructional materials development, teacher professional learning, leadership development, and educational research.</p>

              <p><strong>Susan Loucks-Horsley Memorial Fund</strong></p>
              <p>The purpose of this fund is to support an annual employee recognition dinner in honor of Susan Loucks-Horsley, former associate director of BSCS. Susan Loucks-Horsley was known throughout the United States and Europe for her pioneering research and development work in educational change, professional development, and science and mathematics education. At the time of her death in July 2000, she was Associate Executive Director at BSCS. Susan wanted to recognize the "good works" of the staff at BSCS. This Memorial Fund provides the resources to honor Susan's vision via an annual staff recognition program. Your contribution will help us recognize and reward values that Susan held dear&mdash;collaboration; caring, respect, and support for colleagues and co-workers; demonstrations of initiative and leadership; capacity for the "big picture"; and morale building.</p>
            </div>
            <div className="ml-0 md:pl-3 md:w-1/2 lg:w-2/5 w-full">
              <Donate amounts={[50, 75, 100, 250]} />
                <div className="flex justify-center p-5">
                  <Link to="/donate/supporter-spotlights" >
                    <Button title='Supporter spotlights'><i className="fas fa-camera-retro" />&nbsp; Supporter spotlights</Button>
                  </Link>
                </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  )
}

export default DonatePage

export const query = graphql`
  query {
    astronautGirl: file(relativePath: { eq: "astronaut-girl-wide.jpg" }) {
      ...rowImage
    }
  }
`
