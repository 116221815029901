import React, { useState, useEffect } from 'react'

import {
  Button,
  ContentArea,
  FormCheckboxField,
  FormDropdownField,
  FormNumberField,
  FormRadioField,
  FormTextField
} from '@bscs-dev-team/bscs-design-system-core'


type DonateProps = {
  amounts: (string | number)[]
}

const getAmountPlusCreditCardFee = (amount: number): number => {
  return Math.round(amount + (amount * .03) + .3)
}

const Donate = ({
  amounts
}: DonateProps) => {
  const [addCreditCardFee, setAddCreditCardFee] = useState<boolean>(false)
  const [customAmountValue, setCustomAmountValue] = useState<string>('')
  const [disabled, setDisabled] = useState<boolean>(true)
  const [donationAmountValid, setDonationAmountValid] = useState<boolean>(false)
  const [donationAmountValue, setDonationAmountValue] = useState<string>('')
  const [inMemoryHonorOf, setInMemoryHonorOf] = useState<string>('')
  const [selectedDefault, setSelectedDefault] = useState<string>(amounts[0].toString())
  const [selectedFund, setSelectedFund] = useState<string>('Annual Fund')


  const defaultDonationAmounts: string[] = [
    ...amounts.map((amount: string | number) => amount.toString()),
    'Other'
  ]

  const fundOptions: string[] = [
    'Annual Fund',
    'Endowment Fund',
    'Equity & Social Justice Initiative',
    'SLH Memorial Fund'
  ]

  useEffect(() => {
    if (donationAmountValid) {
      setDisabled(false)
      return
    }

    setDisabled(true)
  }, [donationAmountValid])

  useEffect(() => {
    if (selectedDefault !== 'Other') {
      setDisabled(false)
      setCustomAmountValue('0')

      if (addCreditCardFee) {
        const adjustedAmount: number = getAmountPlusCreditCardFee(parseInt(selectedDefault))

        setDonationAmountValue(adjustedAmount.toString())
        setDonationAmountValid(true)
        return
      }

      setDonationAmountValue(selectedDefault)
      setDonationAmountValid(true)
      return
    }

    if (addCreditCardFee) {
      const adjustedAmount: number = getAmountPlusCreditCardFee(parseInt(customAmountValue))

      setDonationAmountValue(adjustedAmount.toString())
      setDonationAmountValid(true)
      return
    }

    setDonationAmountValue(customAmountValue)
    setDonationAmountValid(true)
  }, [selectedDefault, addCreditCardFee, customAmountValue])

  useEffect(() => {
    if (customAmountValue === '0') {
      setDisabled(true)
      return
    }

    setDisabled(false)
  }, [customAmountValue])

  return (
    <ContentArea size='md'>
      <h2>Donation Form</h2>
      <div className='w-full mb-4'>
        <FormDropdownField
          dropdownItems={fundOptions}
          setValue={(value: string) => setSelectedFund(value)}
          title='Donation Fund'
        />
      </div>

      <div className='w-full mb-4'>
        <FormRadioField
          labels={defaultDonationAmounts}
          setValue={(value: string) => setSelectedDefault(value)}
        />
      </div>

      {selectedDefault === 'Other' &&
        <div className='w-full mb-4'>
          <FormNumberField
            label='Gift Amount'
            min={1}
            setValue={(value: string) => setCustomAmountValue(value)}
            setValid={(valid: boolean) => setDonationAmountValid(valid)}
          />
        </div>
      }
      <div className='w-full mt-8'>
        <FormCheckboxField
          label='I choose to help cover costs and processing fees to make my donation go further.'
          setValue={(value: boolean) => setAddCreditCardFee(value)}
        />
      </div>
      {
        !isNaN(parseInt(donationAmountValue)) && donationAmountValue !== '0'
          ?
            <div style={{ height: '70px' }}>
              <p>
                Please direct my <strong>${donationAmountValue}.00
                </strong> gift to the <strong>{selectedFund}</strong>.
              </p>
            </div>
          :
            <div style={{ height: '70px' }} />
      }
      <div className='w-full mt-4'>
        <FormTextField
          id='in-memory-honor-of'
          label='In memory/honor of'
          name='In memory/honor of'
          optional={true}
          setValue={(value: string) => setInMemoryHonorOf(value)}
          setValid={(valid: boolean) => valid}
        />
      </div>
      <div className='flex justify-end flex-wrap mt-8'>
        <div className='ml-auto'>
            <div className={selectedFund === 'Annual Fund' ? 'block' : 'hidden'}>
              <Button
                className='snipcart-add-item btn btn-outline-primary'
                data-item-id='oneTimeDonationAnnualFund'
                data-item-name='Donation: Annual Fund'
                data-item-price='1.00'
                data-item-description='Donation: Annual Fund'
                data-item-metadata={JSON.stringify({
                  'sendto': ['Lauren Novo', 'Aleigh Raffelson', 'Val Maltese'],
                  'inMemoryHonorOf': inMemoryHonorOf
                })}
                data-item-shippable={false}
                data-item-url={process.env.GATSBY_ENVIRONMENT === 'production'
                  ? 'https://bscs.org/donate'
                  : process.env.GATSBY_ENVIRONMENT === 'stage'
                  ? 'https://stage.bscs.org/donate'
                  : 'http://127.0.0.1:8000/donate'
                }
                data-item-quantity={isNaN(+donationAmountValue) ? 0 : +donationAmountValue}
                disabled={disabled}
                title='Donate to the Annual Fund'
              >
                Donate
              </Button>
            </div>

            <div className={selectedFund === 'Endowment Fund' ? 'block' : 'hidden'}>
              <Button
                className='snipcart-add-item btn btn-outline-primary'
                data-item-id='oneTimeDonationEndowmentFund'
                data-item-name='Donation: Endowment Fund'
                data-item-price='1.00'
                data-item-description='Donation: Endowment Fund'
                data-item-metadata={JSON.stringify({
                  'sendto': ['Lauren Novo', 'Aleigh Raffelson', 'Val Maltese'],
                  'inMemoryHonorOf': inMemoryHonorOf
                })}
                data-item-shippable={false}
                data-item-url={process.env.GATSBY_ENVIRONMENT === 'production'
                  ? 'https://bscs.org/donate'
                  : process.env.GATSBY_ENVIRONMENT === 'stage'
                  ? 'https://stage.bscs.org/donate'
                  : 'http://127.0.0.1:8000/donate'
                }
                data-item-quantity={isNaN(+donationAmountValue) ? 0 : +donationAmountValue}
                disabled={disabled}
                title='Donate to the Endowment Fund'
              >
                Donate
              </Button>
            </div>

            <div className={selectedFund === 'Equity & Social Justice Initiative' ? 'block' : 'hidden'}>
              <Button
                className='snipcart-add-item btn btn-outline-primary'
                data-item-id='oneTimeDonationEquityAndSocialJusticeInitiative'
                data-item-name='Donation: Equity & Social Justice Initiative'
                data-item-price='1.00'
                data-item-description='Donation: Equity & Social Justice Initiative'
                data-item-metadata={JSON.stringify({
                  'sendto': ['Lauren Novo', 'Aleigh Raffelson', 'Val Maltese'],
                  'inMemoryHonorOf': inMemoryHonorOf
                })}
                data-item-shippable={false}
                data-item-url={process.env.GATSBY_ENVIRONMENT === 'production'
                  ? 'https://bscs.org/donate'
                  : process.env.GATSBY_ENVIRONMENT === 'stage'
                  ? 'https://stage.bscs.org/donate'
                  : 'http://127.0.0.1:8000/donate'
                }
                data-item-quantity={isNaN(+donationAmountValue) ? 0 : +donationAmountValue}
                disabled={disabled}
                title='Donate to the Equity & Social Justice Initiative'
              >
                Donate
              </Button>
            </div>

            <div className={selectedFund === 'SLH Memorial Fund' ? 'block' : 'hidden'}>
              <Button
                className='snipcart-add-item btn btn-outline-primary'
                data-item-id='oneTimeDonationSLHMemorialFund'
                data-item-name='Donation: SLH Memorial Fund'
                data-item-price='1.00'
                data-item-description='Donation: SLH Memorial Fund'
                data-item-metadata={JSON.stringify({
                  'sendto': ['Lauren Novo', 'Aleigh Raffelson', 'Val Maltese'],
                  'inMemoryHonorOf': inMemoryHonorOf
                })}
                data-item-shippable={false}
                data-item-url={process.env.GATSBY_ENVIRONMENT === 'production'
                  ? 'https://bscs.org/donate'
                  : process.env.GATSBY_ENVIRONMENT === 'stage'
                  ? 'https://stage.bscs.org/donate'
                  : 'http://127.0.0.1:8000/donate'
                }
                data-item-quantity={isNaN(+donationAmountValue) ? 0 : +donationAmountValue}
                disabled={disabled}
                title='Donate to the SLH Memorial Fund'
              >
                Donate
              </Button>
            </div>
          </div>
        </div>
    </ContentArea>
  )
}

export default Donate
